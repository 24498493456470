import { CognitoUserPool } from 'amazon-cognito-identity-js';

const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;

if (!userPoolId || !clientId) {
  throw new Error(
    'Missing required Cognito configuration. Please ensure REACT_APP_USER_POOL_ID and REACT_APP_CLIENT_ID are set in your environment.'
  );
}

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
  AuthenticationFlowType: 'USER_SRP_AUTH'
};

export default new CognitoUserPool(poolData);
